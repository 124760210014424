import { isProduction } from 'utils/common';
import tracking from 'utils/tracking';
import { IConversionData, ITrackPage } from './types';

const MNTN_PIXEL_URL = `${process.env.REACT_APP_MNTN_URL}/sti?`;
const MNTN_ID = process.env.REACT_APP_MNTN_ID;

const useMountain = () => {
  const track = (pixelUrl: string) => {
    try {
      if (!isProduction()) {
        return;
      }

      const randomNumber = Math.floor(Math.random() * 1e17);
      const imagePixelUrl = `${pixelUrl}shaid=${MNTN_ID}&cb=${randomNumber}`;

      // Create image pixel
      const img = new Image();
      img.src = imagePixelUrl;
      img.width = 0;
      img.height = 0;
      img.style.display = 'none';
      document.body.appendChild(img);
    } catch (error) {
      tracking.errorV2('Failed to load Mountain pixel', error);
    }
  };

  const trackMountainConversion = ({ eventName, ltv }: IConversionData) => {
    const params = new URLSearchParams({
      conv: '1',
      shoid: eventName,
      shoamt: ltv.toString()
    });
    const pixelUrl = `${MNTN_PIXEL_URL}${params.toString()}&`;
    track(pixelUrl);
  };

  const trackMountainPage = ({ planNameAlias, ltv, eventName }: ITrackPage) => {
    const params = new URLSearchParams({
      shadditional: `eventName=${eventName},LTV=${ltv}${planNameAlias ? `,planNameAlias=${planNameAlias}` : ''}`
    });
    const pixelUrl = `${MNTN_PIXEL_URL}${params.toString()}&`;
    track(pixelUrl);
  };

  return {
    trackMountainPage,
    trackMountainConversion
  };
};

export default useMountain;

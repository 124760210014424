export const formatPriceToNumber = (price: string, defaultPrice?: number): number | null => {
  if (!price || Array.isArray(price)) {
    return defaultPrice || null;
  }
  const parseNum = parseFloat(price);

  if (Number.isNaN(parseNum)) {
    return defaultPrice || null;
  }
  return Number(parseNum.toFixed(2));
};

export const formatPriceNoTrailingZero = (price: string, defaultPrice?: number, toString?: boolean) => {
  if (!price || Array.isArray(price)) {
    return defaultPrice || null;
  }
  const parseNum = parseFloat(price);

  if (Number.isNaN(parseNum)) {
    return defaultPrice || null;
  }

  if (toString) {
    // Check if the number has decimal places
    return parseNum % 1 === 0 ? parseNum.toString() : parseNum.toFixed(2);
  }

  // Return as a number
  return parseNum % 1 === 0 ? parseNum : Number(parseNum.toFixed(2));
};
